import { host } from "./const";

const apiClient = {
    host: host,

    createClient(data) {
        return fetch(`${this.host}/client`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    updateClient(data) {
        return fetch(`${this.host}/client`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(data)
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getClientsByCompanyId(companyId) {
        return fetch(`${this.host}/clients-company/${companyId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getClientsByCompanyBranchId(branchId) {
        return fetch(`${this.host}/clients-company-branch/${branchId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getClientsBySearchString(searchString, branchId) {
        return fetch(`${this.host}/clients-company-branch-by-string/${searchString}/${branchId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getClientById(clientId, companyBranchId) {

        return fetch(`${this.host}/client-by-id/${clientId}/${companyBranchId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    refreshPasswordClient(clientId, companyBranchId) {

        return fetch(`${this.host}/client-password-refresh/${clientId}/${companyBranchId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getClientAbonements({ clientId, companyBranchId }) {
        return fetch(`${this.host}/abonements-by-client-and-branch/${clientId}/${companyBranchId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    addClientOncompanyBranch({ clientId, companyBranchId }) {
        return fetch(`${this.host}/client/add/companyBranch`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ clientId, companyBranchId })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    }

}

export default apiClient;