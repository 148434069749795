import { useEffect, useState } from "react";
import apiClient from "../../../../../../../../../api/apiClient";
import { useStudio } from "../../../../../../../../../context/studioContext";


function useLogicFormAddCurrentClient({ handleSuccessAdd }) {
    const { studio } = useStudio();
    const [clients, setClients] = useState([]);
    const [isLoaing, setIsLoaing] = useState(false);
    const [validMessage, setValidMessage] = useState();
    const [openedForm, setOpenedForm] = useState('')
    const [selectedClient, setSelectedClient] = useState();
    const [isLoadingClient, setIsLoadingClient] = useState(false);

    async function getClients() {
        try {
            setIsLoaing(true);
            const data = await apiClient.getClientsByCompanyId(studio.company)
            const clients = data.clients.filter((client) => !client.companyBranches.includes(studio._id))
            setClients(clients);
            setIsLoaing(false);
        }
        catch (err) {
            setIsLoaing(false);
            console.log(err)
            setValidMessage(err.message)
        }
    }

    function selectClient(data) {
        setSelectedClient(data);
        setOpenedForm('FormConfirm')
    }

    async function addClient() {
        try {
            setIsLoadingClient(true);
            const client = await apiClient.addClientOncompanyBranch({ companyBranchId: studio._id, clientId: selectedClient._id })
            setIsLoadingClient(false);
            handleSuccessAdd(client);
        }
        catch (err) {
            setIsLoadingClient(false);
            setValidMessage(err.message)
        }
    }

    useEffect(() => {
        if (studio) {
            getClients()
        }
    }, [studio])

    return ({
        clients,
        validMessage,
        isLoaing,
        selectClient,
        addClient,
        openedForm, setOpenedForm,
        selectedClient,
        isLoadingClient,
    })
}

export default useLogicFormAddCurrentClient;