import ButtonWithBorder from "../../buttons/ButtonWithBorder/ButtonWithBorder";

function FormTwoTextButton({
    title,
    nameButtonOne,
    nameButtonTwo,
    clickButtonOne,
    clickButtonTwo,
    isLoading,
    textForFill, // текст для заполнения формы им 
    cleanTextForFill, // очистить текст для заолнения
}) {

    return (
        <div className="form-two-text-button">
            {textForFill ?
                <div>
                    <p>{textForFill}</p>
                    <ButtonWithBorder
                        text={'Назад'}
                        onClick={cleanTextForFill}
                    />
                </div>
                :
                <>
                    <p className="formadd__title">{title}</p>
                    <div className="form-two-text-button__container-buttons">
                        <div className="form-two-text-button__button">
                            <ButtonWithBorder text={nameButtonOne} onClick={clickButtonOne} isLoading={isLoading} />
                        </div>
                        <div className="form-two-text-button__button">
                            <ButtonWithBorder text={nameButtonTwo} onClick={clickButtonTwo} />
                        </div>
                    </div>
                </>
            }
        </div >

    )
}

export default FormTwoTextButton;