import { host } from "./const";

const apiCompanyBranch = {
    host: host,

    update(data, media) {
        const formData = new FormData();
        // Добавляем все поля из объекта data в formData
        Object.keys(data).forEach(key => {
            if (data[key]) {
                formData.append(key, data[key]);
            }
        });
        if (media) {
            formData.append('media', media);
        }

        return fetch(`${this.host}/company-branch`,
            {
                method: 'PATCH',
                credentials: 'include',
                body: formData,
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },
    updateSettingsPush(data) {
        return fetch(`${this.host}/company-branches/update/push`,
            {
                method: 'PATCH',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    }
}

export default apiCompanyBranch;