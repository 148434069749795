import React from "react";
import InputText from "../../../commonComponents/inputs/InputText/InputText.js";
import ButtonWithBorder from "../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder.jsx";
import useSettingPushClietnBeforeTraining from "../logic/useSettingPushClietnBeforeTraining.ts";

const FormSettingPushClientBeforeTrainingText = () => {
    const {
        isLoading,
        updateText,
        text, setText,
    } = useSettingPushClietnBeforeTraining();

    return (
        <form>
            <InputText
                inputValue={text}
                setInputChange={setText}
                placeholder={'Текст'}
                bottomText={''}
                type="text"
                labelTop={'Текст'}
                isRequired={true}
            />
            <div className="margin-top__30px">
                <ButtonWithBorder
                    text={"Редактировать"}
                    onClick={updateText}
                    isLoading={isLoading}
                />
            </div>
        </form>
    )
}

export default FormSettingPushClientBeforeTrainingText;