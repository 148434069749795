import { useState } from "react";
import useHandleSuccess from "./hooks/useHandleSuccess";
import useForm from "./hooks/useForm";
import useClient from "./hooks/useClient";
import useAbonements from "./hooks/useAbonements";

function useLogicClientPage() {
    const [selectedAbonement, setSelectedAbonement] = useState();
    const [selectedTraining, setSelectedTraining] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [validMessage, setValidMessage] = useState();

    const {
        client, setClient
    } = useClient();

    const {
        abonements,
        fullAbonementList,
        abonementType, setAbonementType,
        abonementSettigsInputOptions,
        fill,
    } = useAbonements({
        client,
        setValidMessage,
        setIsLoading,
    })

    const {
        openedForm, setOpenedForm,
        openAbonement,
    } = useForm({
        setAbonementType,
        setSelectedAbonement,
    });

    const {
        handleSuccessUpdate,
        handleSuccessRemoveVisit,
    } = useHandleSuccess({ setClient, fill, setOpenedForm })

    function handleSelectTraining(training) {
        setSelectedTraining(training)
        setOpenedForm('FormRemoveVisit')
    }

    function closeFormAbonement(){
        setOpenedForm();
        setSelectedAbonement();
    }

    return ({
        client,
        openedForm, setOpenedForm,
        selectedAbonement,
        abonementType,
        fullAbonementList,
        selectedTraining,
        openAbonement,
        abonements,
        abonementSettigsInputOptions,
        handleSelectTraining,
        handleSuccessUpdate,
        handleSuccessRemoveVisit,
        isLoading,
        validMessage,
        closeFormAbonement,
    })
}

export default useLogicClientPage;