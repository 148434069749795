import React from "react";
import ModalWithChildren from "../../../../../../../../commonComponents/modal/ModalWithChildren";
import FormAddorUpdateClient from "./FormAddorUpdateClient";
import useControlForm from "./logic/useControlForm.ts";
import FormChoice from "../FormChoice/FormChoice";
import FormAddCurrentClient from "../FormAddCurrentClient/FormAddCurrentClient";

function FormAddClient({
    isOpen,
    isClose,
    handleSuccessAdd,
}) {

    const { form, setForm } = useControlForm();
    function hide(){
        isClose();
        setForm('choice');
    }

    return (
        <ModalWithChildren
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={hide} // клик на кнопку для сворачивания
            children={
                <>
                    {form === 'create' &&
                        <FormAddorUpdateClient
                            textButtonSubmit={'Создать'}
                            handleSuccessAdd={handleSuccessAdd}
                        />}
                    {form === 'choice' &&
                        <FormChoice
                            addCurrent={() => setForm('addCurrent')}
                            addNew={() => setForm('create')}
                        />}
                    {form === 'addCurrent' &&
                        <FormAddCurrentClient handleSuccessAdd={handleSuccessAdd}/>}
                </>
            }
        />
    )
}

export default FormAddClient;