import { host } from "./const";

const apiPush = {
    host: host,

    sendPush(clientsIds, companyBranchId, title, message) {
        return fetch(`${this.host}/clients/push`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({
                    clientsIds,
                    companyBranchId,
                    title,
                    message,
                })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },


}

export default apiPush;