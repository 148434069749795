import React from "react";
import Preloader from "../../../../../../../../commonComponents/loaders/Preloader/Preloader";
import useLogicFormAddCurrentClient from "./logic/useLogicFormAddCurrentClient";
import ClientInfo from "../../../../../../../../commonComponents/pages/ClientInfo/ClientInfo";
import FormTwoTextButton from "../../../../../../../../commonComponents/forms/FormTwoTextButton/FormTwoTextButton";

/** Форма добавления  филиалу из списка существующих */
function FormAddCurrentClient({handleSuccessAdd}) {

    const {
        clients,
        validMessage,
        isLoaing,
        selectedClient,
        selectClient,
        addClient,
        openedForm, setOpenedForm,
        isLoadingClient,
    } = useLogicFormAddCurrentClient({handleSuccessAdd});

    return (
        !isLoaing ?
            <>
                {validMessage &&
                    <p>{validMessage}</p>
                }
                {openedForm !== 'FormConfirm' && !validMessage &&
                    < div className="list margin-top__30px">
                        {clients !== undefined ?
                            clients.map(client => {
                                return (
                                    <button
                                        className={`list__element`}
                                        id={client._id}
                                        key={client._id}
                                        onClick={(e) => selectClient(client)} // клик на клиента в списке
                                    >
                                        <ClientInfo client={client} />
                                    </button>)
                            })
                            : <></>
                        }
                    </div>
                }
                {openedForm === 'FormConfirm' && !validMessage &&
                    <FormTwoTextButton
                        title={`Добавить ${selectedClient?.name} ${selectedClient?.surname}`}
                        nameButtonOne={'Да'}
                        nameButtonTwo={'Нет'}
                        clickButtonOne={addClient}
                        clickButtonTwo={() => setOpenedForm()}
                        isLoading={isLoadingClient}
                    />}
            </>
            : <Preloader />
    )
}

export default  FormAddCurrentClient;