import { useEffect, useState } from "react"
import { useStudio } from "../../../context/studioContext";
import apiCompanyBranch from "../../../api/apiCompanyBranch";

function useSettingPushClietnBeforeTraining() {
    const { studio } = useStudio();
    const [isLoading, setIsLoading] = useState<'text' | 'minutes' | undefined>();
    const [minutes, setMinutes] = useState(0);
    const [text, setText] = useState('');

    async function update() {
        try {
            setIsLoading('minutes');
            const data = {
                companyBranchId: studio._id,
                field: 'clientBeforeTrainingMinutes',
                value: minutes,
            }
            console.log(data);
            await apiCompanyBranch.updateSettingsPush(data);
            setIsLoading(undefined);
        }
        catch (err) {
            console.log(err);
            setIsLoading(undefined);
        }
    }

    async function updateText() {
        try {
            setIsLoading('text');
            const data = {
                companyBranchId: studio._id,
                field: 'clientBeforeTrainingText',
                value: text,
            }
            await apiCompanyBranch.updateSettingsPush(data);
            setIsLoading(undefined);
        }
        catch (err) {
            console.log(err);
            setIsLoading(undefined);
        }
    }

    function fill() {
        if (studio) {
            console.log(studio.notificationSettings)
            setMinutes(studio.notificationSettings.clientBeforeTrainingMinutes)
            setText(studio.notificationSettings.clientBeforeTrainingText)
        }
    }

    useEffect(() => {
        fill();
    }, [studio])

    return ({
        isLoading,
        minutes, setMinutes,
        update,
        updateText,
        text, setText,
    })
}

export default useSettingPushClietnBeforeTraining;