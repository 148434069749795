import React, { createContext, useContext, useEffect, useState, } from 'react';
import { useStudio } from './studioContext';

const StyleContext = createContext();

export const StyleContextProvider = ({ children }) => {
    const { studio } = useStudio();
    const [colorText, setColorText] = useState('text-color-flex');
    const [backgroundColor, setBackgroundColor] = useState('background-color__flex');

    function controlTextColorBytheme(theme) {
        // Выбираем все элементы <p> и <h>
        const paragraphs = document.querySelectorAll('p');
        const headers = document.querySelectorAll('h1, h2, h3, h4, h5, h6');

        // Удаляем все классы, начинающиеся на "text-color"
        paragraphs.forEach(p => {
            p.classList.forEach(className => {
                if (className.startsWith('text-color')) {
                    p.classList.remove(className);
                }
            });
        });

        headers.forEach(h => {
            h.classList.forEach(className => {
                if (className.startsWith('text-color')) {
                    h.classList.remove(className);
                }
            });
        });

        // Добавляем класс в зависимости от темы
        const classNameText = theme.includes('Flex') ? 'text-color-flex' : 'text-color-dark';
        // Добавляем класс к элементам <p>
        paragraphs.forEach(p => {
            p.classList.add(classNameText);
        });
        // Добавляем класс к элементам <h1> - <h6>
        headers.forEach(h => {
            h.classList.add(classNameText);
        });
    }

    function updateTheme(theme) {
        const mainElement = document.querySelector('.App');

        // Удаляем все классы, начинающиеся на "app__"
        mainElement.classList.forEach(className => {
            if (className.startsWith('app__')) {
                mainElement.classList.remove(className);
            }
        });
        // Добавляем класс в зависимости от темы
        if (theme.includes('Flex')) {
            mainElement.classList.add('app__flex');
            setBackgroundColor('background-color__flex')
            setColorText('text-color-flex')
        } else if (theme.includes('DARK')) {
            mainElement.classList.add('app__dark');
            setBackgroundColor('background-color__dark')
            setColorText('text-color-dark')
        }
        controlTextColorBytheme(theme);
    }

    useEffect(() => {
        if (studio) {
            updateTheme(studio.name)
        }
    }, [studio]);



    return (
        <StyleContext.Provider value={{
            colorText,
            backgroundColor,
        }}>
            {children}
        </StyleContext.Provider>
    )

}


export const useStyle = () => {
    return useContext(StyleContext);
};