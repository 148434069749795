/**
 * Компонент для ввода текста с меткой и сообщением об ошибке.
 * @param {Object} props - Свойства компонента.
 * @param {string} props.inputValue - Текущее значение ввода.
 * @param {Function} props.setInputChange - Функция обратного вызова для установки нового значения ввода.
 * @param {string} props.placeholder - Плейсхолдер для поля ввода.
 * @param {string} props.bottomText - Текст сообщения внизу.
 * @param {string} props.label - Метка поля ввода.
 * @param {string} [props.type="text"] - Тип поля ввода (text, password, number и т. д.).
 * @param {string} [props.isRequired=true] - обязательно или нет к заполнению
* @param {boolean} props.isDisabled - Неизменяемое ли поле.
 * @returns {JSX.Element} - Элемент компонента InputText.
 */
function InputText({
    inputValue,
    setInputChange,
    placeholder,
    bottomText,
    labelTop,
    label,
    labelPosition = 'inline',
    type = 'text',
    isRequired = true,
    isDisabled = false,
}) {

    function handleInputChange(e) {
        setInputChange(e.target.value);
    }

    return (
        <div className="input-container">
            {labelTop &&
                <p className="input__label">{labelTop}</p>}
            <div className="input-with-label-container">
                {label ?
                    <p className="input__label">{label}:</p>
                    : <></>}
                <input
                    type={type ? type : "text"}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    className={`input`}
                    id={label ? label : new Date().toString()}
                    minLength="1"
                    required={isRequired}
                    disabled={isDisabled}
                />
            </div>
            {/* <span className="input__text-error" id="error-firstname">{bottomText}</span> */}
        </div>
    )
}

export default InputText;