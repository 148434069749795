import { useState } from "react";
import useClient from "./useClient";
import apiPush from "../../../../api/apiPush";
import { useStudio } from "../../../../context/studioContext";
import useLogicClientPage from "../useLogicClientPage";

function useSendPush() {
    const { client } = useClient();
    const { studio } = useStudio();
    const { setOpenedForm } = useLogicClientPage()
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState<string>('')

    async function sendPush() {
        try {
            setIsLoading(true);
            await apiPush.sendPush([client._id], studio._id, title, text)
            setIsLoading(false);
            setOpenedForm();
        }
        catch (err) {
            setIsLoading(false);
        }
    }

    return ({
        isLoading,
        title, setTitle,
        text, setText,
        sendPush,
    })
}

export default useSendPush;