import { useState } from "react"

function useControlForm() {
    const [form, setForm] = useState<'create'|'choice'|'addCurrent'>('choice')

    return ({
        form,
        setForm,
    })
}

export default useControlForm;